<template>
  <div class="wrapper">
    <div style="margin: 250px auto; background: white; width: 500px; border-radius: 10px; overflow: hidden;">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane label="账户密码登录" name="first">
          <div style="margin: 20px auto; background-color: #fff; width: 350px; height: 260px; padding: 20px; border-radius: 10px">
            <el-form :model="user" :rules="rules" ref="userForm">
              <el-form-item prop="username">
                <el-input size="medium" prefix-icon="el-icon-user" v-model="user.username" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input size="medium" prefix-icon="el-icon-lock" show-password v-model="user.password" placeholder="请输入密码"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div class="code-input-container">
                  <el-input size="small" placeholder="请输入验证码" prefix-icon="el-icon-circle-check" v-model="user.code" @keydown.enter.native="loginAccount" />
                  <valid-code @update:value="getCode"/>
                </div>
              </el-form-item>
              <el-form-item class="login-buttons">
                <el-button type="info" size="medium" icon="el-icon-thumb" @click="$router.push('/register')" round>注册新账号</el-button>
                <el-button type="primary" size="medium" icon="el-icon-circle-check" @click="loginAccount" round>立即登录</el-button>
              </el-form-item>
              <el-form-item style="margin: 10px 0; text-align: right">
                <el-button type="text" size="medium" autocomplete="off" @click="handlePass" style="color: #fa2424; font-weight: bold;">忘记密码</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="邮箱验证登录" name="second">
         <el-link type="danger" @click="$router.push('/register')" style="display: block; text-align: center;">先注册绑定邮箱，即可使用邮箱登录</el-link>
          <div style="margin: 20px auto; background-color: #fff; width: 350px; height: 250px; padding: 20px; border-radius: 10px">
            <el-form :model="user" :rules="rules" ref="userForm">
              <el-form-item prop="email">
                <el-input size="medium" prefix-icon="el-icon-message" v-model="user.email" placeholder="请输入邮箱"></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input size="small" prefix-icon="el-icon-lock" style="width: 180px" v-model="user.code" placeholder="请输入验证码"></el-input>
                <el-button type="primary" size="medium" class="ml-5" autocomplete="off" @click="showValidCodeDialog">发送验证码</el-button>
              </el-form-item>
              <el-form-item class="login-buttons">
                <el-button type="info" size="medium" icon="el-icon-thumb" @click="$router.push('/register')" round>注册新账号</el-button>
                <el-button type="primary" size="medium" icon="el-icon-circle-check" @click="loginEmail" round>立即登录</el-button>
              </el-form-item>
              <el-form-item style="margin: 10px 0; text-align: right">
                <el-button type="text" size="medium" autocomplete="off" @click="handlePass" style="color: #fa2424; font-weight: bold;">忘记密码</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog title="安全验证" :visible.sync="dialogVisible" width="20%" :before-close="handleClose">
      <div style="display: flex; align-items: center;">
        <el-input v-model="userValidCode" placeholder="请输入验证码" style="margin-right: 10px;"></el-input>
        <ValidCode ref="validCode" @update:value="updateValidCode" style="display: inline-block;"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="verifyValidCode">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="忘记密码" :visible.sync="dialogFormVisible" width="25%">
      <el-form label-width="100px" size="small">
        <el-form-item label="邮箱">
          <el-input v-model="pass.email" placeholder="请输入账号绑定的邮箱" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="验证码">
          <el-input size="small" style="width: 200px" v-model="pass.code" placeholder="请输入验证码"></el-input>
          <el-button type="primary" size="small" class="ml-5" autocomplete="off" @click="sendEmailCode(2)">发送验证码</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" size="medium" @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" size="medium" @click="passwordBack">重置密码</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {resetRouter, setRoutes} from "@/router";
import ValidCode from "@/components/ValidCode";
import webSocketService from "@/services/websocketService";

export default {
  name: "Login",
  data() {
    // 验证码校验
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else if (value.toLowerCase() !== this.code) {
        callback(new Error('验证码输入错误!!'))
      } else {
        callback()
      }
    }
    return {
      user: {},
      pass: {},
      code: '',
      activeName: 'first',
      dialogFormVisible: false,
      dialogVisible: false,
      userValidCode: '',
      validCode: '',
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        code: [
          { validator: validateCode, trigger: 'blur' }
        ],
      }
    }
  },
  components: {ValidCode},
  methods: {
    sendEmailCode(type) {
      let email;
      if (type === 1) {
        email = this.user.email
      } else if (type === 2) {
        email = this.pass.email
      }
      if (!email){
        this.$message.error("请输入您的邮箱")
        return
      }
      if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(email)) {
        this.$message.error("请输入正确的邮箱")
        return
      }
      this.request.get("/user/email/" + email + "/" + type).then(res => {
        if (res.code === '200') {
          this.$message.success("验证码发送成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    loginAccount() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.request.post("/user/loginAccount", this.user).then(res => {
            if(res.code === '200') {
              // 存储用户信息到浏览器
              localStorage.setItem("user", JSON.stringify(res.data))
              // 存储用户信息到浏览器
              localStorage.setItem("menus", JSON.stringify(res.data.menus))

              // 动态设置当前用户的路由
              setRoutes()
              webSocketService.initWebSocket(); // 初始化 WebSocket
              this.$router.push("/")
              this.$message.success("登录成功")
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      });
    },
    getCode(code) {
      this.code = code.toLowerCase()
    },
    loginEmail() {
      if (!this.user.email){
        this.$message.error("请输入您的邮箱")
        return
      }
      if (!this.user.code){
        this.$message.error("请输入您的验证码")
        return
      }
      this.request.post("/user/loginEmail", this.user).then(res => {
        if(res.code === '200') {
          localStorage.setItem("user", JSON.stringify(res.data))  // 存储用户信息到浏览器
          localStorage.setItem("menus", JSON.stringify(res.data.menus))  // 存储用户信息到浏览器

          // 动态设置当前用户的路由
          setRoutes()
          this.$router.push("/")
          this.$message.success("登录成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handlePass() {
      this.dialogFormVisible = true
      this.pass = {}
    },
    passwordBack() {
      this.request.put("/user/reset", this.pass).then(res => {
        if (res.code === '200') {
          this.$message.success("重置密码成功，新密码为：123456，请尽快修改密码")
          this.dialogFormVisible = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showValidCodeDialog() {
      this.dialogVisible = true;
      this.userValidCode = ''; // 清空上一次输入的内容
      this.$nextTick(() => {
        this.$refs.validCode.refreshCode();
      });
    },
    verifyValidCode() {
      if (this.userValidCode.toLowerCase() === this.validCode.toLowerCase()) {
        this.dialogVisible = false;
        this.sendEmailCode(1);
      } else {
        this.$message.error("验证码错误");
        this.$refs.validCode.refreshCode();
      }
    },
    updateValidCode(value) {
      this.validCode = value;
    },
    handleClose(done) {
      this.dialogVisible = false;
      done();
    }
  }
}
</script>

<style>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(to bottom, #f8f9fa, #c036f6);
}

/* 新增验证码输入框和验证码生成器的容器样式 */
.code-input-container {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
}
.login-buttons {
  display: flex;
  justify-content: space-between;
}

</style>
